jQuery(document).ready(function($) {
	/********************************
	 THREE BY SLIDER
	*********************************/
	$('.three-by-slider-wrapper .slides').slick({
		arrows: false, 
		nextArrow: '<button type="button" class="slick-next"><i class="fa-light fa-angle-right"></i></button>',
		prevArrow: '<button type="button" class="slick-prev"><i class="fa-light fa-angle-left"></i></button>',
		slidesToShow: 4,
		responsive: [
			{
			 	breakpoint: 1024,
			  	settings: {
					dots: true,
					slidesToShow: 3
			  }
			},
			{
				breakpoint: 768,
				settings: {
					dots: true,
					slidesToShow: 2
				}
			},
			{
			  	breakpoint: 600,
			  	settings: {
					arrows: true,
					dots: true,
					slidesToShow: 1
			  }
			}
		  ]
	});


}); /* end of as page load scripts */